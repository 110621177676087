import React, { useState,useEffect } from 'react'
import ship from "../../assets/images/ship.svg";
import plus1 from "../../assets/images/plus1.svg";
import Lockvip from "../../assets/images/LockImg.png"

import lock from "../../assets/images/lock.svg";

import config from "../../config/index"
import { setMyDraft } from "../../action/user.action"
import { getUserDraft } from "../../redux/features/draft/mydraftslice"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { toastAlert } from "../../utils/toast";


function MarketplaceList(props) {

  
      
    const navigate = useNavigate();

    const dispatch = useDispatch();
    var { productlist } = props
    const [loading, setloading] = useState(undefined)
    const [toggleview, settoggleview] = useState(props.listType)

    const userdraft = useSelector((state) => state?.userdraft);

    const User = useSelector((state) => state?.account);

    // console.log(User, "User");


    useEffect(() => {
        settoggleview(props.listType)
      }, [props.listType])

    

    const handleDraft = async (pid) => {
        try {
            // console.log(pid,);
            var reqdata = {
                productid: pid
            }
            var { status, message } = await setMyDraft(reqdata)
            if (status) {
                setloading(pid)
                dispatch(getUserDraft());
                setTimeout(() => {
                    setloading(undefined)
                }, 2000);
            } else {
                toastAlert("error", message);
            }

        } catch (err) {
            console.log(err);
        }

    }


    const ButtonDisplay = (props) => {
        var { pid } = props
        const findone = userdraft.find((data) => (data.product_id == pid.product_id))
        return (
            <>
                {findone != undefined ?
                    <button className="primary_btn_success w-100 p-2 text-center"
                        style={{ backgroundColor: "green" }}
                    >
                        <i class="fa fa-check" aria-hidden="true"></i>

                        Added To Cart
                    </button>
                    :
                    pid.vip_product ?

                        <>
                            <button className="primary_btn w-100 p-2 text-center"
                                onClick={User.vip_status ? () => navigate(`/vip-marketplace`) : () => navigate(`/marketing-package`)}>

                                <img src={lock} className="img-fluid me-2 mb-1" alt="img" />
                                Import To Cart
                            </button>
                        </>
                        :
                        <>
                            <button className="primary_btn w-100 p-2 text-center" onClick={() => handleDraft(pid.product_id)}>


                                <img src={plus1} className="img-fluid me-2 mb-1" alt="img" />
                                Import To Cart
                            </button>
                        </>
                }


            </>
        )

    }

    const numberWithCommas = (number) => {
        return number.toLocaleString()
      };


    return (
        <div>
            <div className="row">
                 {productlist && productlist.length > 0 && productlist.map((item, index) => {
                   
                    return (
                       
                    <div className={(toggleview=="grid")?"col-lg-4 col-xxl-3 d-flex girdView":"col-lg-12 col-xxl-12 d-flex listView"}
                    
                    >

                        <div className="box"
                        //  data-aos="fade-up"  data-aos-delay={(index+1)*50} data-aos-duration="1000"
                        >
                            <div className="img_box">

                                {item.vip_product ?

                                    <a style={{ cursor: 'pointer' }}
                                        href={User.vip_status ? `/product-details/Vipmarketplace/${item._id}` : `/marketing-package`}>
                                        <img src={`${config.API_URL}/product/${item.product_image[0]}`} class="img-fluid" alt="img" />
                                    </a>
                                    :

                                    <a style={{ cursor: 'pointer' }}
                                        href={`/product-details/marketplace/${item._id}`}>
                                        <img src={`${config.API_URL}/product/${item.product_image[0]}`} class="img-fluid" alt="img" />
                                    </a>
                                }
                            </div>
                            <div className='product-list-detail-box'>
                                <div className='list-3'>
                            <div className="bach" >{item?.product_title}</div>
                            {item.vip_product ?

                                <>
                                    <p className="sm_txt" style={{ opacity: "50%" }}>{item?.description}</p>
                                    <img
                                        style={{
                                            position: "absolute",
                                            marginTop: "-144px",
                                            marginLeft: "50px"
                                        }}
                                        src={Lockvip}
                                    />
                                </> :
                                <p className="sm_txt" >{item?.description}</p>
                            }
                            </div>
                            <div className='list-4'>
                            <h5>Product Cost - ${numberWithCommas(item?.product_cost)} </h5>
                            <h5>Import Cost - ${numberWithCommas(item?.import_cost)}</h5>
                            <h6 style={{fontSize:'12px', color:'rgb(22 144 170)'}}>(One Time Payment)</h6>
                            </div>
                            {/* <div className="detail" >
                                <img src={ship} className="img-fluid" alt="img" />
                                <span>Shipping time: 2 Business Days</span>
                            </div> */}
                            <ButtonDisplay pid={item} />
                            </div>
                        </div>

                    </div>
                    
                    )

                      })}
              


            </div>


        </div >
    )
}

export default MarketplaceList