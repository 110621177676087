import { Navigate, Outlet } from "react-router-dom";
// import Login from "./Login";
import Cookies from "js-cookie";

const useAuth = () => {

    const userToken = Cookies.get("userToken");
    if (userToken) {
        return true
    } else {
        return false
    }

}
const LoginRouter = () => {
    const isAuth = useAuth();
    return isAuth ? <Navigate to="/dashboard" /> : <Outlet />
};

export default LoginRouter