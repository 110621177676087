import React, { useState, useEffect } from 'react'
import ship from "../../assets/images/ship.svg";
import plus1 from "../../assets/images/plus1.svg";
import config from "../../config/index"
import { setMyDraft } from "../../action/user.action"
import { getUserDraft } from "../../redux/features/draft/mydraftslice"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { toastAlert } from "../../utils/toast";
import EditSellCostPop from "../../components/editsellpop/EditSellCostPop"
import DeletePop from "../../components/deleteProduct/DeletePop"

function MyProductList(props) {

    const navigate = useNavigate();

    const dispatch = useDispatch();
    var { myproduct } = props
    const [loading, setloading] = useState(undefined)
    const [show, setshow] = useState(false);
    const [productInfo, setproductInfo] = useState([]);
    const [showdelete, setshowdelete] = useState(false);

    const [toggleview, settoggleview] = useState(props.listType)



    useEffect(() => {
        settoggleview(props.listType)
    }, [props.listType])

    const handleClose = () => {
        setshow(false);
    };

    const handleClickPop = (data) => {
        setproductInfo(data)
        setshow(true)
    }

    const showDeletePop = (data) => {
        setproductInfo(data)
        setshowdelete(true)
    }

    const explorePage = (id) => {
        navigate(`/myproduct-details/${id}`);

    }

    const numberWithCommas = (number) => {
        return number?.toLocaleString()
    };

    // console.log(myproduct,'myproduct');

    return (
        <div>
            <div className="row">
                {myproduct && myproduct?.map((item, index) => (
                    <div className={(toggleview == "grid") ? "col-lg-4 col-xxl-3 d-flex girdView" : "col-lg-12 col-xxl-12 d-flex listView"}>

                        <div className="box">
                            <div className="img_box">
                                <a style={{ cursor: 'pointer' }} href={`/myproduct-details/${item?._id}`}>
                                    <img src={`${config.API_URL}/product/${item?.product?.product_image[0]}`} class="img-fluid" alt="img" />
                                </a>
                            </div>
                            <div className='product-list-detail-box'>
                                <div className='list-3'>
                                    <div className="bach" >{item?.product?.product_title}</div>
                                    <p className="sm_txt" >{item?.product?.description}</p>
                                </div>
                                <div className='list-4'>

                                <h5> Store Name: &nbsp;&nbsp;{item?.userstore
                                    ?.store_name}-{item?.default_storeid?.Store_name}</h5>
                                <h5> Sell Cost:&nbsp;&nbsp;${numberWithCommas(item?.sell_cost)} &nbsp;&nbsp;
                                    <i onClick={() => handleClickPop(item)} style={{ fontSize: "14px", cursor: "pointer" }} className="fa" >&#xf044;</i>

                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <i onClick={() => showDeletePop(item)} style={{ fontSize: "14px", cursor: "pointer" }} className="fa" >&#xf014;</i>

                                </h5>
                                <h5>Total Orders : {item?.count?.[0]? numberWithCommas( item?.count?.[0]?.orderCount- item?.count?.[0]?.ReturnCount) : 0}</h5>
                                </div>
                                <button
                                    className="primary_btn w-100 p-2 text-center"
                                    onClick={() => explorePage(item?._id)}
                                >
                                    View Product
                                </button>
                            </div>                        </div>
                    </div>
                ))
                }
            </div>

            <EditSellCostPop show={show}
                setshow={setshow}
                handleClose={handleClose}
                productInfo={productInfo}
            />

            <DeletePop showdelete={showdelete} setshowdelete={setshowdelete} productid={productInfo} />

        </div >
    )
}

export default MyProductList