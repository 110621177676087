import { viewUserProfile } from "../../../action/user.action";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    id: "",
    userName: "",
    email: "",
    phoneNo: "",
    uniqueId: "",
    emailStatus: "",
    phoneStatus: "",
    memberType: "",
    deposit_Balance: "",
    plan_id: "",
    plan_type: "",
    userBankdetails: ''
};

export const getUserProfile = createAsyncThunk("account", async () => {
    // console.log('rrrrr');
    const { status, result } = await viewUserProfile();
    if (status) return result;
    else return initialState;
});

const todoSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        accountReset: (state, action) => {
            state = initialState;
            return state;
        },
        deleteTodo: (state, action) => {
            state.tasks = state.tasks.filter((task) => task.id !== action.payload);
        },
    },
    extraReducers: (build) => {
        build.addCase(getUserProfile.fulfilled, (state, action) => {
            state = action.payload;
            return state;
            
        });
    },
});

export const { deleteTodo, accountReset } = todoSlice.actions;

export default todoSlice.reducer;

