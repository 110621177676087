import React, { useState } from "react";
import { InputGroup, Form, Accordion } from "react-bootstrap";
// img
import usr from "../assets/images/usr.svg";

import Sidebar from "../components/sidebar.js";
import Navbarinner from "../components/Navbarinner.js";

// import SupportCreate from "../components/support/SupportCreate"
import SupportList from "../components/support/SupportList";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount"
import CreateReferal from "../components/refferal/createRefferal.js";

export default function Refferal() {



    return (
        <>  <ScrollToTopOnMount />

            <div className="page_wrapper" >
                <Sidebar navLink={"/referral"} />
                <div className="w-100" >
                    <Navbarinner />
                    <div className="right_wrapper" >
                        <div className="row">
                            <div className="col-lg-4">

                                <CreateReferal />
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}
