import { getDefaultStore } from "../../../action/user.action";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    Store_name: "",
    logo: "",
    site_url: "",
    status: ""
};

export const getDefStore = createAsyncThunk("defaultstore", async () => {
    const { status, result } = await getDefaultStore();
    if (status) return result;
    else return initialState;
});

const todoSlice = createSlice({
    name: "defaultstore",
    initialState,
    reducers: {
        accountReset: (state, action) => {
            state = initialState;
            return state;
        },

    },
    extraReducers: (build) => {
        build.addCase(getDefStore.fulfilled, (state, action) => {
            state = action.payload;
            return state;
        });
    },
});

export const { deleteTodo, accountReset } = todoSlice.actions;

export default todoSlice.reducer;
