import { getMyDraft } from "../../../action/user.action";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    userId: "",
    product_cost: "",
    sell_cost: "",
    import_cost: "",
    Ship_Cost: "",
    status:"",
    product_title:"",
    categorie_id:""
};

export const getUserDraft = createAsyncThunk("userdraft", async () => {
    const { status, result } = await getMyDraft();
    if (status) return result;
    else return initialState;
});



const todoSlice = createSlice({
    name: "userdraft",
    initialState,
    reducers: {
        accountReset: (state, action) => {
            state = initialState;
            return state;
        },

    },
    extraReducers: (build) => {
        build.addCase(getUserDraft.fulfilled, (state, action) => {
            state = action.payload;
            return state;
        });
    },
});

export const { deleteTodo, accountReset } = todoSlice.actions;

export default todoSlice.reducer;



