import React from 'react'
import { Modal, Dropdown, Nav, Tab } from "react-bootstrap";
import { deleteMyProduct } from "../../action/user.action"
import { toastAlert } from "../../utils/toast";
import {
    Alert,
} from "react-bootstrap";
import { getMyproduct } from "../../redux/features/getmyProduct/myProductSlice";
import { useDispatch } from "react-redux";

function DeletePop(props) {
    var { showdelete, setshowdelete, productid } = props
    const dispatch = useDispatch();

    const HandleDelete = async () => {
        try {

            var reqdata = {
                id: productid._id
            }
            var { status, message } = await deleteMyProduct(reqdata)
            if (status) {
                toastAlert("success", "Product Deleted Successfully")
                dispatch(getMyproduct());


                setTimeout(() => {
                    window.location.href="/myproduct"
                }, (1000));
            }


        } catch (err) {
            console.log(err);
        }
    }




    return (
        <>

            <Modal show={showdelete} size="sm" className="custom_modal">
         
                <Modal.Header className="mb-3">
             
                {/* <br></br> */}
                    <div className="cursor-pointer">
                        <h6>Are You sure to Delete?</h6>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <button
                        class="secondary_btn"
                        style={{ backgound: "none !important" }}
                        onClick={() => setshowdelete(false)}
                    >
                        {" "}
                        Cancel
                    </button>
                    &nbsp; &nbsp;
                    <button class="primary_btn" onClick={HandleDelete}>
                        {" "}
                        Confirm
                    </button>
                </Modal.Body>
                <br></br>
                <Alert key={"success"} variant={"warning"} className="p-1">
                    <i className="me-2 fa-solid fa-info-circle"></i>
                    Note: We didn't refund your Import cost for Deleting this Product
                </Alert>
            </Modal>
        </>
    )
}

export default DeletePop