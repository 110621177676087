// import packages
import axios from "axios";

// import config
import config from "../config";

// import utils
import { getAuthorizationHeader } from "../utils/cookies";
import { isEmpty } from "../helper/validation";

const instance = axios.create({
  baseURL: config.API_URL,
  // timeout: 1000,
});

instance.interceptors.request.use(function (config) {
  const token = getAuthorizationHeader().Authorization;
  if (!isEmpty(token)) {
    config.headers.Authorization = token;
  }
  return config;
});



export default instance;
