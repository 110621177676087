import React from 'react'
import {
    Alert,
} from "react-bootstrap";
import login from "../assets/images/login.svg";
import login_logo from "../assets/images/login_logo.svg";

function ResendMail() {
    return (
        <>
            <div className="login">
                <div className="left" >
                    <div className="w-75" >
                        <h2>Verify Your Account</h2>

                        <Alert key={"primary"} variant={"primary"} className="p-2">
                            <i className="me-2 fa fa-info-circle"></i>
                            We have sent you the activation link again. kindly check
                            your Inbox/Spam folder!
                        </Alert>
<br></br>
                        <a href="/login"
                            className="primary_btn mb-4 w-100 rounded-2"                    >
                            Sign In
                        </a>
                    </div>
                </div>
                <div className="right d-none d-lg-flex">
                    <div className="text-center" >
                        <img src={login_logo} className="img-fluid mb-4" alt="img" />
                        <p className="para mb-5" >Log in now to ShoppyAI to better manage your dropshipping account and increase your profits!</p>
                        <img src={login} className="img-fluid" alt="img" />

                    </div>
                </div>
            </div>

        </>


    )
}

export default ResendMail