import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import { Scrollbars } from "react-custom-scrollbars-2";
import DatePicker from "react-datepicker";
import { Tab, Nav } from "react-bootstrap";
import Slider from "react-slick";
import "react-datepicker/dist/react-datepicker.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GaugeChart from 'react-gauge-chart'
// img
import csv from "../assets/images/csv.svg";
import xlsx from "../assets/images/xlsx.svg";
import ship from "../assets/images/ship.svg";
import plus1 from "../assets/images/plus1.svg";
import star_dark from "../assets/images/star_dark.svg";
import star from "../assets/images/star.svg";
import time from "../assets/images/time.svg";

import Sidebar from "../components/sidebar.js";
import MyChart from "../components/linechart.js";
import MyLineChart from "../components/linechart.js";
import Navbarinner from "../components/Navbarinner.js";
import { Link, useParams } from "react-router-dom";

import { ProductInfo } from "../action/user.action";
import moment from "moment";
import config from "../config/index";

import { setMyDraft } from "../action/user.action";
import { getUserDraft } from "../redux/features/draft/mydraftslice";
import { useDispatch, useSelector } from "react-redux";

import { toastAlert } from "../utils/toast";
import EditSellCostPop from "../components/editsellpop/EditSellCostPop"
import ScrollToTopOnMount from "../components/ScrollToTopOnMount"
import ProductDetailSlider from "../components/ProductSlider/ProductDetailSlider";
import VipProductSlider from "../components/ProductSlider/VipproductSlider"


export default function Productdetails() {
  const { type, id } = useParams();
  const dispatch = useDispatch();

  const [productInfo, setproductInfo] = useState([]);
  const [loading, setloading] = useState(false);
  const [activeTab, setActiveTab] = useState(1); // Initially set the first tab as active

  const [profitPer, setprofitPer] = useState(0);

  const userdraft = useSelector((state) => state?.userdraft);

  useEffect(() => {
    getProductInfo();
  }, [id]);

  const getProductInfo = async () => {
    try {
      var { status, message, result, percentage } = await ProductInfo(id);

      // console.log(result, percentage, 'percentage');

      if (result) {

        setprofitPer(percentage[0]?.nooforder || 0)


        let reviewarr = []
        for (let i = 1; i <= 5; i++) {
          if (result?.review_star >= i) {
            reviewarr.push(true)
          } else {
            reviewarr.push(false)
          }
        }
        result.reviewarr = reviewarr

        setproductInfo(result)

      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDraft = async (pid) => {
    try {
      var reqdata = {
        productid: pid,
      };
      var { status, message } = await setMyDraft(reqdata);
      if (status) {
        dispatch(getUserDraft());

        setloading(true);
        setTimeout(() => {
          setloading(false);
        }, 2000);
      } else {
        toastAlert("error", message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const settings = {
    customPaging: function (i) {
      // console.log(i, "hhhhhhh");
      return (


        <a>
          <img
            src={`${config.API_URL}/product/${productInfo?.product_image[i]}`}
            className="img-fluid"
            alt="img"
          />
        </a>

      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const ButtonDisplay = (props) => {
    var { pid } = props;
    const findone = userdraft.find((data) => data.product_id == pid);
    return (
      <>
        {findone != undefined ? (
          <button
            className="primary_btn_success mt-3"
            style={{ backgroundColor: "green" }}
          >
            <i class="fa fa-check" aria-hidden="true"></i>
            Added to Draft
          </button>
        ) : (
          <button className="primary_btn mt-3" onClick={() => handleDraft(pid)}>
            <img src={plus1} className="img-fluid me-2 mb-1" alt="img" />
            Import Product
          </button>
        )}
      </>
    );
  };

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  var settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  return (
    <div className="page_wrapper">
      <ScrollToTopOnMount />

      <Sidebar />
      <div className="w-100">
        <Navbarinner />
        <div className="right_wrapper overflow-x-hidden">
          <h2 className="h3tag bdr mb-3">Product Details </h2>
          <div className="pdt_det">
            <div className="row">
              <div className="col-lg-5 mb-4 mb-lg-0">
                <div className="right_img slider-container">
                  <Slider {...settings}>
                    {productInfo?.product_image &&
                      productInfo.product_image.map((item, index) => (

                        <div className="box" key={index}>
                          <img
                            src={`${config.API_URL}/product/${item}`}
                            className="img-fluid"
                            alt="img"
                          />
                        </div>

                      ))}

                    {/* <div>
                      <div className="box" >
                        <img src={require(`../assets/images/joystick1.png`)} className="img-fluid" alt="img" />
                      </div>
                    </div>
                    <div>
                      <div className="box" >
                        <img src={require(`../assets/images/joystick1.png`)} className="img-fluid" alt="img" />
                      </div>
                    </div>
                    <div>
                      <div className="box" >
                        <img src={require(`../assets/images/joystick1.png`)} className="img-fluid" alt="img" />
                      </div>
                    </div>
                    <div>
                      <div className="box" >
                        <img src={require(`../assets/images/joystick1.png`)} className="img-fluid" alt="img" />
                      </div>
                    </div>
                    <div>
                      <div className="box" >
                        <img src={require(`../assets/images/joystick1.png`)} className="img-fluid" alt="img" />
                      </div>
                    </div> */}
                  </Slider>
                </div>
              </div>
              <div className="col-lg-7">
                <h3 className="h3tag">{productInfo?.product_title || "-"}</h3>
                <div className="sub_tit">
                  Product ID:
                  <span> {productInfo?.product_id || "-"}</span>|{" "}
                  <span> {productInfo?.review_count || 0}</span> Reviews |
                  <div className="d-flex">
                    {productInfo?.reviewarr?.map((item, index) => (
                      <img src={item ? star : star_dark} className="img-fluid" alt="img" />
                    ))
                    }</div>
                  {/* // <div className="d-flex">
                  //   <img src={star} className="img-fluid" alt="img" />
                  //   <img src={star} className="img-fluid" alt="img" />
                  //   <img src={star} className="img-fluid" alt="img" />
                  //   <img src={star} className="img-fluid" alt="img" />
                  //   <img src={star_dark} className="img-fluid" alt="img" />
                  // </div> */}
                  {productInfo?.review_star}
                  {/* | Created:{" "}
                  <img src={time} className="img-fluid" alt="img" />
                  <span>
                    {" "}
                    {moment(productInfo?.createdAt).startOf("day").fromNow()}
                  </span> */}
                </div>
                <div className="item_box">
                  <div className="d-flex gap-3 mb-3 flex-wrap flex-lg-nowrap">
                    <div>
                      <div className="flx">
                        <div>
                          <span className="d-inline-block mb-2">Item Cost</span>
                          <h3 className="h3tag">${productInfo?.product_cost}</h3>
                        </div>
                        <div>
                          <span className="d-inline-block mb-2">Import Cost:</span>
                          <h3 className="h3tag">${productInfo?.import_cost}</h3>
                        </div>

                        {/* <div className="campass_box" >
                      <GaugeChart
                        id="gauge-chart2"
                        nrOfLevels={20}
                        percent={(Number(profitPer) / 100)}
                        colors={['#5BE12C', '#F5CD19', '#EA4228']}
                      />
                    </div> */}
                      </div>
                      {/* 
                  {loading ?
                    <button className="primary_btn_success  my-3"
                      style={{ backgroundColor: "green" }}
                    >
                      <i class="fa fa-check" aria-hidden="true"></i>

                      Added to Draft
                    </button>
                    :
                    <button className="primary_btn my-3" onClick={() => handleDraft(productInfo?.product_id)}>
                      <img src={plus1} className="img-fluid me-2 mb-1" alt="img" />
                      Import Product
                    </button>
                    
                    } */}

                      {/* 
                  <button className="primary_btn my-3" ><img src={plus1} className="img-fluid me-2 mb-1" alt="img" />Import Product</button> */}

                      <ButtonDisplay pid={productInfo?.product_id} />
                    </div>
                    <div className="w-100" >
                      <div className="review_box ms-0" >
                        <p className="shipping">Number of Orders</p>
                        <GaugeChart
                          id="gauge-chart2"
                          nrOfLevels={30}
                          percent={(Number(profitPer) / 100)}
                          formatTextValue={(value) => value + ""}
                          colors={['#5BE12C', '#F5CD19', '#EA4228']}
                        />
                      </div>
                    </div>
                  </div>
                  <span className="d-block">Ships from:</span>
                  <p className="shipping">
                    $1.99/ <span className="reg"> 22 Business days To </span>{" "}
                    United States
                  </p>
                  <p>{productInfo?.description}</p>

                  {/* <div className="custom_tab">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="first"
                    >
                      <Nav>
                        <Nav.Item>
                          <Nav.Link eventKey="first">Product Info </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second">Specifications</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="third">Policy</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="fourth">Reviews</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          {productInfo?.product_info}
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          {productInfo?.specification}
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                          {productInfo?.policy}
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth">
                          {productInfo?.review}
                          <div className="review_box" >
                          <GaugeChart
                           id="gauge-chart2"  
                           nrOfLevels={20}
                           percent={0.76} 
                          colors={['#5BE12C', '#F5CD19', '#EA4228']}
                          />
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div> */}
                  <div className="make_tab" >
                    <div className="tabs" style={{ cursor: "pointer" }}>
                      <div className={activeTab === 1 ? "tab active" : "tab"} onClick={() => handleTabClick(1)}>Product Info </div>
                      <div className={activeTab === 2 ? "tab active" : "tab"} onClick={() => handleTabClick(2)}>Specifications</div>
                      <div className={activeTab === 3 ? "tab active" : "tab"} onClick={() => handleTabClick(3)}>Policy</div>
                      <div className={activeTab === 4 ? "tab active" : "tab"} onClick={() => handleTabClick(4)}>Reviews</div>
                    </div>

                    <div className="tab_content" >
                      {activeTab === 1 && <div>{productInfo?.product_info}</div>}
                      {activeTab === 2 && <div> {productInfo?.specification}</div>}
                      {activeTab === 3 && <div>{productInfo?.policy}</div>}
                      {activeTab === 4 &&
                        <div>
                          {productInfo?.review}
                          <div className="review_box" >
                            <GaugeChart
                              id="gauge-chart2"
                              nrOfLevels={20}
                              percent={(Number(productInfo?.review_percentage) / 100)}

                              colors={['#5BE12C', '#F5CD19', '#EA4228']}
                            />
                          </div>
                        </div>}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="categories product_slide">
            {type == "Vipmarketplace" ?
              <VipProductSlider ids={id} />
              :
              <ProductDetailSlider ids={id} />}

          </div>
        </div>
      </div>
    </div>
  );
}
