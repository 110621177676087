// import package
import React, { useEffect, useContext, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

// import service
import { getUserProfile } from "../../redux/features/user/userslice";
import { getUserStore } from "../../redux/features/userstore/userStoreSlice";
import { getUserDraft } from "../../redux/features/draft/mydraftslice"
import { getMyproduct } from "../../redux/features/getmyProduct/myProductSlice"
import { getDefStore } from "../../redux/features/defaultstore/defaultStoreSlice";

import { getNotify } from "../../redux/features/notification/notificationSlice"
import Cookies from "js-cookie";

const HelperRoute = () => {
  const dispatch = useDispatch();
  const isOnce = useRef(false);

  // console.log('ttttttttttttt');
  // redux-state
  const { isAuth } = useSelector((state) => state?.auth);

  // console.log(isAuth, "ttttttttttttt");

  // function
  useEffect(() => {
    if (isAuth && !isOnce.current) {
     
      isOnce.current = true;
      dispatch(getUserProfile());
      dispatch(getUserStore());
      dispatch(getUserDraft());
      dispatch(getMyproduct());
      dispatch(getNotify());
      dispatch(getDefStore());

    }
  }, [isAuth, dispatch]);

  return <></>;
};

export default HelperRoute;
