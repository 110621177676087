import { getMystoreProduct } from "../../../action/user.action";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    import_cost: "",
    sell_cost: "",
    status: "",
    userId: "",
    product_title: "",
    product: "",
    product_id: "",
    store_id: "",
    store_name: "",
    createdAt: "",
};

export const getMyproduct = createAsyncThunk("myproduct", async () => {
    const { status, result } = await getMystoreProduct();
    if (status) return result;
    else return initialState;
});

const todoSlice = createSlice({
    name: "myproduct",
    initialState,
    reducers: {
        accountReset: (state, action) => {
            state = initialState;
            return state;
        },

    },
    extraReducers: (build) => {
        build.addCase(getMyproduct.fulfilled, (state, action) => {
            state = action.payload;
            return state;
        });
    },
});

export const { deleteTodo, accountReset } = todoSlice.actions;

export default todoSlice.reducer;
