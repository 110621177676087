let key = {};

let Envname = "live";
if (Envname == "live") {
  // const API_URL = "http://localhost";
  key = {
    API_URL: `https://prodapi.shoppyai.pro`,
    front_URL: "https://shoppyai.pro",
    Recaptchakey: "6LcI0y8pAAAAAC9NUJG6y_rmwxdnqUDP64a0Lp9s",
    stripe:
      "pk_test_51OjxfWSCUxXzYhK4aCbzWhrmDT1a5sUvEMoa2la4XkVWGOLQ3DH0wrG3bIM52Pyy5bkX0Rg809OSKIeOrshKQlTu00eNYhoNQ4",
    getGeoInfo: "https://ipapi.co/json/",
    GoogleOauth:
      "861891002950-37avkj3e9l37j14mrt251p41jn48is5f.apps.googleusercontent.com",
    FaceBookID: "1105572426750654",
  };
} else {
  if (Envname == "demo") {
    // const API_URL = "http://localhost";
    key = {
      API_URL: `https://shoppyapi.wearedev.team`,
      front_URL: "https://shoppyai-frontend.pages.dev",
      Recaptchakey: "6LcI0y8pAAAAAC9NUJG6y_rmwxdnqUDP64a0Lp9s",
      stripe:
        "pk_test_51OjxfWSCUxXzYhK4aCbzWhrmDT1a5sUvEMoa2la4XkVWGOLQ3DH0wrG3bIM52Pyy5bkX0Rg809OSKIeOrshKQlTu00eNYhoNQ4",
      getGeoInfo: "https://ipapi.co/json/",
      GoogleOauth:
        "861891002950-37avkj3e9l37j14mrt251p41jn48is5f.apps.googleusercontent.com",
      FaceBookID: "1105572426750654",
    };
  } else {
    const API_URL = "http://localhost";
    key = {
      API_URL: `${API_URL}:2053`,
      front_URL: "http://localhost:3000",
      Recaptchakey: "6LcI0y8pAAAAAC9NUJG6y_rmwxdnqUDP64a0Lp9s",
      stripe:
        "pk_test_51OjxfWSCUxXzYhK4aCbzWhrmDT1a5sUvEMoa2la4XkVWGOLQ3DH0wrG3bIM52Pyy5bkX0Rg809OSKIeOrshKQlTu00eNYhoNQ4",
      getGeoInfo: "https://ipapi.co/json/",
      GoogleOauth:
        "861891002950-37avkj3e9l37j14mrt251p41jn48is5f.apps.googleusercontent.com",
      FaceBookID: "1105572426750654",
    };
  }
}

export default key;
