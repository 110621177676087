import Cookies from "js-cookie";

export function getAuthorizationHeader() {
  const userToken = Cookies.get("userToken");

  return {
    Authorization: userToken,
  };
}


var inFifteenMinutes = new Date(new Date().getTime() + 1 * 60 * 1000);



export const setUserToken = (token, status) => {

  // console.log(token, status,'token, status');

  status ?
    Cookies.set("userToken", token, { expires: 1, path: "/" })
    :
    Cookies.set("userToken", token, { path: "/" })

};
