import { getMyStore } from "../../../action/user.action";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    userId: "",
    default_storeid: "",
    store_logo: "",
    store_name: "",
    store_location: "",
    status: "",
    update_date: ""
};

export const getUserStore = createAsyncThunk("userstore", async () => {
    const { status, result } = await getMyStore();
    if (status) return result;
    else return initialState;
});

const todoSlice = createSlice({
    name: "userstore",
    initialState,
    reducers: {
        accountReset: (state, action) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (build) => {
        build.addCase(getUserStore.fulfilled, (state, action) => {
            state = action.payload;
            return state;
        });
    },
});

export const { deleteTodo, accountReset } = todoSlice.actions;

export default todoSlice.reducer;
