import React, { useEffect, useState, useRef } from "react";
import DataTable from 'react-data-table-component';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Modal, Dropdown,Nav,Tab } from 'react-bootstrap';
// img 
import all from "../assets/images/all.svg";
import toys from "../assets/images/toys.svg";
import home from "../assets/images/home.svg";
import tools from "../assets/images/tools.svg";

import outdoor from "../assets/images/outdoor.svg";
import sports from "../assets/images/sports.svg";
import pets from "../assets/images/pets.svg";
import electronics from "../assets/images/electronics.svg";
import cloth from "../assets/images/cloth.svg";
import others from "../assets/images/others.svg";
import search_icon from "../assets/images/search_icon.svg";
import left_arw from "../assets/images/left_arw.svg";
import ship from "../assets/images/ship.svg";
import plus1 from "../assets/images/plus1.svg";
import megamart from "../assets/images/megamart.svg";


import Sidebar from "../components/sidebar.js";
import Navbarinner from "../components/Navbarinner.js";



export default function Megamart() {
 
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);


  

  const columns = [
    {
      name: 'Product',
      selector: row => row.product,
      width:"210px",
      cell: (row) => <div className='sem'>{row.product}</div>,
    },
    {
      name: 'Created',
      selector: row => row.created,
      width:"130px",
    },
    {
      name: 'Source Item ID',
      selector: row => row.SourceItemID,
    },
    {
      name: 'Sell Item ID',
      selector: row => row.SellItemID,
    },
    {
      name: 'Actual Price',
      selector: row => row.ActualPrice,
    },
    {
      name: 'Selling price',
      selector: row => row.Sellingprice,
    },
    {
      name: 'Store',
      selector: row => row.Store, 
    },
    {
      name: 'Status',
      selector: row => row.Status, 
    },
  ];

  const data = [
    {
      product: 'Airdopes 381 Tws Earbuds',
      created:'Feb 7th, 2024',
      SourceItemID:'Megastore',
      SellItemID: '#17856',
      ActualPrice: '$130.44',
      Sellingprice:'$150.44',
      Store:'Amazon',
      Status:'Internal Error'
    },
    {
      product: 'Airdopes 381 Tws Earbuds',
      created:'Feb 7th, 2024',
      SourceItemID:'Megastore',
      SellItemID: '#17856',
      ActualPrice: '$130.44',
      Sellingprice:'$150.44',
      Store:'Amazon',
      Status:'Internal Error'
    },
    {
      product: 'Airdopes 381 Tws Earbuds',
      created:'Feb 7th, 2024',
      SourceItemID:'Megastore',
      SellItemID: '#17856',
      ActualPrice: '$130.44',
      Sellingprice:'$150.44',
      Store:'Amazon',
      Status:'Internal Error'
    },

   
  ];

  const CustomNoDataMessage = () => (
    <div className="text-center py-5 my-5" >
      <p>No data</p>
    </div>
  );


  return (
    <div className="page_wrapper" >
      <Sidebar />
      <div className="w-100" >
        <Navbarinner />
        <div className="right_wrapper" >
          <div className="d-flex flex-wrap gap-2 align-items-center justify-content-between mb-3 pb-3 bdr h3tag" >
            <h2 className="h3tag  mb-0 pb-0" >
              <img src={megamart} className="img-fluid" alt="logo" />
            </h2>
            <button className="primary_btn sm_txt py-2" > +  Add Product</button>
          </div>
          <div className="categories">
            <div className="row">
              <div className="col-lg-4 col-xxl-3 d-flex">
                <div className="box">
                  <div className="img_box">
                    <img src={require("../assets/images/product/p1.png")} class="img-fluid" alt="img" />
                  </div>
                  <div className="bach" >Jeff's Store</div>
                  <p className="sm_txt" >Uses a dictionary of over combined wth a handful of model sentence structures, to generatein lorem Isum which looks reasonable.</p>
                  <h5>$8.61 - 12.86</h5>
                  <div className="detail" >
                    <img src={ship} className="img-fluid" alt="img" />
                    <span>Shipping time: 2 Business Days</span>
                  </div>
                  <button className="primary_btn w-100 p-2 text-center"  onClick={handleShow2}>
                    <img src={plus1} className="img-fluid me-2 mb-1" alt="img" />
                    Import Product
                  </button>
                </div>
              </div>
              <div className="col-lg-4 col-xxl-3 d-flex">
                <div className="box">
                  <div className="img_box">
                    <img src={require("../assets/images/product/p2.png")} class="img-fluid" alt="img" />
                  </div>
                  <div className="bach" >www.stanley1913.com</div>
                  <p className="sm_txt" >Uses a dictionary of over combined wth a handful of model sentence structures, to generatein lorem Isum which looks reasonable.</p>
                  <h5>$8.61 - 12.86</h5>
                  <div className="detail" >
                    <img src={ship} className="img-fluid" alt="img" />
                    <span>Shipping time: 2 Business Days</span>
                  </div>
                  <button className="primary_btn w-100 p-2 text-center"  onClick={handleShow2}>
                    <img src={plus1} className="img-fluid me-2 mb-1" alt="img" />
                    Import Product
                  </button>
                </div>
              </div>
              <div className="col-lg-4 col-xxl-3 d-flex">
                <div className="box">
                  <div className="img_box">
                    <img src={require("../assets/images/product/p3.png")} class="img-fluid" alt="img" />
                  </div>
                  <div className="bach" >WOWParts</div>
                  <p className="sm_txt" >Uses a dictionary of over combined wth a handful of model sentence structures, to generatein lorem Isum which looks reasonable.</p>
                  <h5>$8.61 - 12.86</h5>
                  <div className="detail" >
                    <img src={ship} className="img-fluid" alt="img" />
                    <span>Shipping time: 2 Business Days</span>
                  </div>
                  <button className="primary_btn w-100 p-2 text-center"  onClick={handleShow2}>
                    <img src={plus1} className="img-fluid me-2 mb-1" alt="img" />
                    Import Product
                  </button>
                </div>
              </div>
              <div className="col-lg-4 col-xxl-3 d-flex">
                <div className="box">
                  <div className="img_box">
                    <img src={require("../assets/images/product/p4.png")} class="img-fluid" alt="img" />
                  </div>
                  <div className="bach" >Amarjit Singh Kalra</div>
                  <p className="sm_txt" >Uses a dictionary of over combined wth a handful of model sentence structures, to generatein lorem Isum which looks reasonable.</p>
                  <h5>$8.61 - 12.86</h5>
                  <div className="detail" >
                    <img src={ship} className="img-fluid" alt="img" />
                    <span>Shipping time: 2 Business Days</span>
                  </div>
                  <button className="primary_btn w-100 p-2 text-center"  onClick={handleShow2}>
                    <img src={plus1} className="img-fluid me-2 mb-1" alt="img" />
                    Import Product
                  </button>
                </div>
              </div>
              <div className="col-lg-4 col-xxl-3 d-flex">
                <div className="box">
                  <div className="img_box">
                    <img src={require("../assets/images/product/p5.png")} class="img-fluid" alt="img" />
                  </div>
                  <div className="bach" >Jeff's Store</div>
                  <p className="sm_txt" >Uses a dictionary of over combined wth a handful of model sentence structures, to generatein lorem Isum which looks reasonable.</p>
                  <h5>$405.00</h5>
                  <div className="detail" >
                    <img src={ship} className="img-fluid" alt="img" />
                    <span>Shipping time: 2 Business Days</span>
                  </div>
                  <button className="primary_btn w-100 p-2 text-center"  onClick={handleShow2}>
                    <img src={plus1} className="img-fluid me-2 mb-1" alt="img" />
                    Import Product
                  </button>
                </div>
              </div>
              <div className="col-lg-4 col-xxl-3 d-flex">
                <div className="box">
                  <div className="img_box">
                    <img src={require("../assets/images/product/p6.png")} class="img-fluid" alt="img" />
                  </div>
                  <div className="bach" >www.stanley1913.com</div>
                  <p className="sm_txt" >Uses a dictionary of over combined wth a handful of model sentence structures, to generatein lorem Isum which looks reasonable.</p>
                  <h5>$405.00</h5>
                  <div className="detail" >
                    <img src={ship} className="img-fluid" alt="img" />
                    <span>Shipping time: 2 Business Days</span>
                  </div>
                  <button className="primary_btn w-100 p-2 text-center"  onClick={handleShow2}>
                    <img src={plus1} className="img-fluid me-2 mb-1" alt="img" />
                    Import Product
                  </button>
                </div>
              </div>
              <div className="col-lg-4 col-xxl-3 d-flex">
                <div className="box">
                  <div className="img_box">
                    <img src={require("../assets/images/product/p7.png")} class="img-fluid" alt="img" />
                  </div>
                  <div className="bach" >WOWParts</div>
                  <p className="sm_txt" >Uses a dictionary of over combined wth a handful of model sentence structures, to generatein lorem Isum which looks reasonable.</p>
                  <h5>$405.00</h5>
                  <div className="detail" >
                    <img src={ship} className="img-fluid" alt="img" />
                    <span>Shipping time: 2 Business Days</span>
                  </div>
                  <button className="primary_btn w-100 p-2 text-center"  onClick={handleShow2}>
                    <img src={plus1} className="img-fluid me-2 mb-1" alt="img" />
                    Import Product
                  </button>
                </div>
              </div>
              <div className="col-lg-4 col-xxl-3 d-flex">
                <div className="box">
                  <div className="img_box">
                    <img src={require("../assets/images/product/p8.png")} class="img-fluid" alt="img" />
                  </div>
                  <div className="bach" >Amarjit Singh Kalra</div>
                  <p className="sm_txt" >Uses a dictionary of over combined wth a handful of model sentence structures, to generatein lorem Isum which looks reasonable.</p>
                  <h5>$405.00</h5>
                  <div className="detail" >
                    <img src={ship} className="img-fluid" alt="img" />
                    <span>Shipping time: 2 Business Days</span>
                  </div>
                  <button className="primary_btn w-100 p-2 text-center"  onClick={handleShow2}>
                    <img src={plus1} className="img-fluid me-2 mb-1" alt="img" />
                    Import Product
                  </button>
                </div>
              </div>
              <div className="col-lg-4 col-xxl-3 d-flex">
                <div className="box">
                  <div className="img_box">
                    <img src={require("../assets/images/product/p1.png")} class="img-fluid" alt="img" />
                  </div>
                  <div className="bach" >Jeff's Store</div>
                  <p className="sm_txt" >Uses a dictionary of over combined wth a handful of model sentence structures, to generatein lorem Isum which looks reasonable.</p>
                  <h5>$8.61 - 12.86</h5>
                  <div className="detail" >
                    <img src={ship} className="img-fluid" alt="img" />
                    <span>Shipping time: 2 Business Days</span>
                  </div>
                  <button className="primary_btn w-100 p-2 text-center"  onClick={handleShow2}>
                    <img src={plus1} className="img-fluid me-2 mb-1" alt="img" />
                    Import Product
                  </button>
                </div>
              </div>
              <div className="col-lg-4 col-xxl-3 d-flex">
                <div className="box">
                  <div className="img_box">
                    <img src={require("../assets/images/product/p2.png")} class="img-fluid" alt="img" />
                  </div>
                  <div className="bach" >www.stanley1913.com</div>
                  <p className="sm_txt" >Uses a dictionary of over combined wth a handful of model sentence structures, to generatein lorem Isum which looks reasonable.</p>
                  <h5>$8.61 - 12.86</h5>
                  <div className="detail" >
                    <img src={ship} className="img-fluid" alt="img" />
                    <span>Shipping time: 2 Business Days</span>
                  </div>
                  <button className="primary_btn w-100 p-2 text-center"  onClick={handleShow2}>
                    <img src={plus1} className="img-fluid me-2 mb-1" alt="img" />
                    Import Product
                  </button>
                </div>
              </div>
              <div className="col-lg-4 col-xxl-3 d-flex">
                <div className="box">
                  <div className="img_box">
                    <img src={require("../assets/images/product/p3.png")} class="img-fluid" alt="img" />
                  </div>
                  <div className="bach" >WOWParts</div>
                  <p className="sm_txt" >Uses a dictionary of over combined wth a handful of model sentence structures, to generatein lorem Isum which looks reasonable.</p>
                  <h5>$8.61 - 12.86</h5>
                  <div className="detail" >
                    <img src={ship} className="img-fluid" alt="img" />
                    <span>Shipping time: 2 Business Days</span>
                  </div>
                  <button className="primary_btn w-100 p-2 text-center"  onClick={handleShow2}>
                    <img src={plus1} className="img-fluid me-2 mb-1" alt="img" />
                    Import Product
                  </button>
                </div>
              </div>
              <div className="col-lg-4 col-xxl-3 d-flex">
                <div className="box">
                  <div className="img_box">
                    <img src={require("../assets/images/product/p4.png")} class="img-fluid" alt="img" />
                  </div>
                  <div className="bach" >Amarjit Singh Kalra</div>
                  <p className="sm_txt" >Uses a dictionary of over combined wth a handful of model sentence structures, to generatein lorem Isum which looks reasonable.</p>
                  <h5>$8.61 - 12.86</h5>
                  <div className="detail" >
                    <img src={ship} className="img-fluid" alt="img" />
                    <span>Shipping time: 2 Business Days</span>
                  </div>
                  <button className="primary_btn w-100 p-2 text-center"  onClick={handleShow2}>
                    <img src={plus1} className="img-fluid me-2 mb-1" alt="img" />
                    Import Product
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* modal  */}
          <Modal show={show2} centered size="xl" className="custom_modal" onHide={handleClose2}>
            <Modal.Header className="mb-3" closeButton  >
              <div className="cursor-pointer" >
              <img src={left_arw} className="img-fluid me-2 mb-1" alt="logo" />
                Back</div>
            </Modal.Header>
            <Modal.Body>
              <div className="import_box" >
              <h6>Import Products #31141263 (Import to Store)</h6>
              <p>Actions: Import to Store</p>
              <div className="custom_tab">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Nav>
                    <Nav.Item>
                      <Nav.Link eventKey="first">All Product (1)   </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Failed (0) </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Successful (0)</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <Scrollbars style={{ width: "100%", height: 400 }}     >
                      <div className="custom_table_chg" >
                          <DataTable
                            columns={columns}
                            data={data}
                            noDataComponent={<CustomNoDataMessage />}
                            pagination
                            paginationPerPage={5}
                            paginationRowsPerPageOptions={[5, 10, 15, 20]} 
                          />
                        </div>
                      </Scrollbars>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">Second tab content</Tab.Pane>
                    <Tab.Pane eventKey="third">third tab content</Tab.Pane>
                    <Tab.Pane eventKey="fourth">fourth tab content</Tab.Pane>

                  </Tab.Content>
                </Tab.Container>
              </div>
              </div>
              <div class="add"><button class="primary_btn"> +  Add Store</button></div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}
